import { Button, Collapse, Icon, message } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { oc } from 'ts-optchain';

import { environment } from '../../../environments/environment';
import { Price } from '../../components';
import { i18n } from '../../services/i18n';
import { OrderEntry } from './components';
import { apiClient } from '../../services/api-client';
import { StartProcessAction } from '../../../features/dateChange/ducks';
import { Entry, Transaction } from '../../models/NewTransaction';

interface Props {
    transaction: Transaction;
    onChangeDate: (data: StartProcessAction) => void;
}

export default ({ transaction, onChangeDate }: Props) => {
    const canBeChanged = useMemo(() => {
        const { entries } = transaction;

        const isItemInFuture = (entry: Entry) => {
            if (!oc(entry).meta.timeSlot.from() || entry.meta.timeSlotType === 'day') {
                return moment(entry.meta.validFrom)
                    .isSameOrAfter(moment(), 'day');
            }

            return moment(
                    `${entry.meta.validFrom} ${entry.meta.timeSlot.from}`,
                    'YYYY-MM-DD HH:mm'
                )
                .isAfter(moment(), 'minute');
        };

        return transaction.status === 'finished' &&
            entries
                .filter(e => e.type === 'product')
                .every(e =>
                    !e.reversed &&
                    !!oc(e).product.meta.allowDateChange() &&
                    oc(e).ticketSystem.output.NERRORNO(-1) === 0 &&
                    isItemInFuture(e)
                );
    }, [transaction]);

    function handleDownloadTicket(e) {
        e.stopPropagation();

        window.open(
            `${environment.protocol}://${environment.host}/transactions/${transaction._id}/ticket?lang=${i18n.language}`
        );
    }

    function handleDownloadPrintTickets(e) {
        e.stopPropagation();

        window.open(
            `${environment.protocol}://${environment.host}/transactions/${transaction._id}/print-all-tickets?lang=${i18n.language}`
        );
    }

    async function handleDownloadInvoice(e) {
        e.stopPropagation();

        if (transaction.origin === 'ssp') {
            window.open(oc(transaction).meta.receipt.url(), '_blank');
        } else if (transaction.amount > 0) {
            window.open(oc(transaction).meta.invoice.pdfURL(), '_blank');
        }
    }

    const handleChangeTransactionItems = () => {
        onChangeDate({
            transactionId: transaction._id,
            email: transaction.meta.email,
            items: transaction.entries.filter(e => e.type === 'product')
        });
    };

    const handleChangeSingleTransactionItem = (item: Entry) => {
        onChangeDate({
            transactionId: transaction._id,
            email: transaction.meta.email,
            items: [item]
        });
    };

    return (
        <div className="order">
            <Collapse>
                <Collapse.Panel
                    header={
                        <>
                            <div className="order-header">
                                <div className="order-header-title">
                                    <h3>{moment(transaction.createdAt).format('DD.MM.YYYY')}</h3>
                                    <p>
                                        {i18n.t('order.orderNumber')} {transaction._id}<br/>
                                        ({i18n.t(`order.origin.${transaction.origin}`)})
                                    </p>
                                </div>
                                <div className="order-header-right">
                                    <div className="order-header-price">
                                        <h3>
                                            <Price value={transaction.amount} />
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="order__controls">
                                {canBeChanged && (
                                    <Button
                                        className="control-button"
                                        type="default"
                                        size="small"
                                        onClick={handleChangeTransactionItems}
                                    >
                                        {i18n.t('order.changeDate')}
                                    </Button>
                                )}
                                {!transaction.entries.some(e => !!e.reversed || !!e.meta.giftTemplate) && (
                                    <Button
                                        className="control-button"
                                        type="primary"
                                        size="small"
                                        onClick={handleDownloadTicket}
                                    >
                                        {i18n.t('order.downloadTicket')}
                                        <Icon type="download" />
                                    </Button>
                                )}
                                {environment.config.downloadPrintTickets && transaction.salesPoint === 'b2b' && !transaction.entries.some(e => !!e.reversed) && (
                                    <Button
                                        className="control-button"
                                        type="primary"
                                        size="small"
                                        onClick={handleDownloadPrintTickets}
                                    >
                                        {i18n.t('order.downloadPrintTickets')}
                                        <Icon type="download" />
                                    </Button>
                                )}
                                {transaction.amount > 0 && (
                                    <Button
                                        className="control-button"
                                        type="primary"
                                        size="small"
                                        onClick={handleDownloadInvoice}
                                    >
                                        {i18n.t('order.downloadInvoice')}
                                        <Icon type="download" />
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                    key="1"
                >
                    {transaction.entries.map((item, index) => (
                        <OrderEntry
                            key={index}
                            entry={item}
                            transactionId={transaction._id}
                            onChangeDate={handleChangeSingleTransactionItem}
                        />
                    ))}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};
