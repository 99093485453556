import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { AppState } from '../../../common/models/AppState';
import { Benefit, CreditBenefit, PartnerBenefit, VoucherBenefit } from '../../../common/models/Benefit';
import {
    createActionCreator,
    createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../../common/utils/redux-helpers';
import { environment } from '../../../environments/environment';
import {
    selectUserProfile,
} from '../../user/ducks';
import { api } from '../api';
import { selectCart } from '../../shopping/ducks/cart';
import { Transaction } from '../../../common/models/NewTransaction';

// Action types
export enum PersonalActionTypes {
    FetchBenefits = '@@Personal/FETCH_BENEFITS',
    FetchVIPBenefits = '@@Personal/FETCH_VIP_BENEFITS',
    FetchMyPartnerBenefits = '@@Personal/FETCH_MY_PARTNER_BENEFITS',
    FetchPartnerBenefits = '@@Personal/FETCH_PARTNER_BENEFITS',
    FetchUserCredits = '@@Personal/FETCH_USER_CREDITS',
    FetchCreditBenefits = '@@Personal/FETCH_CREDIT_BENEFITS',
    FetchOrders = '@@Personal/FETCH_ORDERS',
    FetchVoucherBenefits = '@@Personal/FETCH_VOUCHER_BENEFITS',
}

// Actions
export const fetchOrdersActions = createApiActionCreators(PersonalActionTypes.FetchOrders);
export const fetchDiscountsAction = createActionCreator(PersonalActionTypes.FetchBenefits);
export const fetchVIPBenefitsActions = createApiActionCreators(
    PersonalActionTypes.FetchVIPBenefits
);
export const fetchPartnerBenefitsActions = createApiActionCreators(
    PersonalActionTypes.FetchPartnerBenefits
);
export const fetchMyPartnerBenefitsActions = createApiActionCreators(
    PersonalActionTypes.FetchMyPartnerBenefits
);
export const fetchUserCreditsActions = createApiActionCreators(
    PersonalActionTypes.FetchUserCredits
);
export const fetchCreditBenefitsActions = createApiActionCreators(
    PersonalActionTypes.FetchCreditBenefits
);
export const fetchVoucherBenefitsActions = createApiActionCreators(
    PersonalActionTypes.FetchVoucherBenefits
);

// Reducers
export interface DiscountsState {
    voucher: VoucherBenefit[];
    credit: CreditBenefit[];
    vip: Benefit[];
    partner: PartnerBenefit[];
    myPartner: PartnerBenefit[];
}

export interface PersonalState {
    discounts: DiscountsState;
    credit: number;
    orders: Transaction[];
}

const initialState: Partial<PersonalState> = {
    discounts: {
        voucher: [],
        credit: [],
        vip: [],
        partner: [],
        myPartner: [],
    },
    orders: [],
};

const discounts = createReducer(initialState.discounts, {
    [PersonalActionTypes.FetchPartnerBenefits]: {
        [RequestActionTypes.SUCCESS]: (
            state: DiscountsState,
            payload: PartnerBenefit[]
        ): DiscountsState => ({
            ...state,
            partner: payload
        }),
    },
    [PersonalActionTypes.FetchMyPartnerBenefits]: {
        [RequestActionTypes.SUCCESS]: (
            state: DiscountsState,
            payload: PartnerBenefit[]
        ): DiscountsState => ({
            ...state,
            myPartner: payload
        }),
    },
    [PersonalActionTypes.FetchCreditBenefits]: {
        [RequestActionTypes.SUCCESS]: (
            state: DiscountsState,
            payload: CreditBenefit[]
        ): DiscountsState => ({
            ...state,
            credit: payload,
        }),
    },
    [PersonalActionTypes.FetchVIPBenefits]: {
        [RequestActionTypes.SUCCESS]: (
            state: DiscountsState,
            payload: Benefit[]
        ): DiscountsState => ({
            ...state,
            vip: payload,
        }),
    },
    [PersonalActionTypes.FetchVoucherBenefits]: {
        [RequestActionTypes.SUCCESS]: (
            state: DiscountsState,
            payload: VoucherBenefit[]
        ): DiscountsState => ({
            ...state,
            voucher: payload,
        }),
    },
});

const credit = createReducer(initialState.orders, {
    [PersonalActionTypes.FetchUserCredits]: {
        [RequestActionTypes.SUCCESS]: (state: Transaction[], payload: Transaction[]) => payload,
    },
});

const orders = createReducer(initialState.orders, {
    [PersonalActionTypes.FetchOrders]: {
        [RequestActionTypes.SUCCESS]: (state: Transaction[], payload: Transaction[]) => payload,
    },
});

const shopping = combineReducers<PersonalState>({
    orders,
    discounts,
    credit,
});

export default persistReducer(
    {
        key: 'discounts',
        version: 0,
        storage: localForage,
        whitelist: [],
        debug: environment.debug,
    },
    shopping
);

// Selectors
export const selectPersonal = (state: AppState) => state.personal;
export const selectDiscounts = (state: AppState) => selectPersonal(state).discounts;
export const selectUserOrders = (state: AppState) => selectPersonal(state).orders;
export const selectUserCredit = (state: AppState) => selectPersonal(state).credit;
export const selectCartCredit = (state: AppState) => selectCart(state).credit;

// Sagas
function* watchPartnerBenefits() {
    const resp = yield call(api.getDiscountsBenefits);

    if (resp.ok) {
        yield put(fetchPartnerBenefitsActions.success(resp.data));
    } else {
        yield put(fetchPartnerBenefitsActions.success([]));
    }
}

function* watchMyPartnerBenefits() {
    const resp = yield call(api.getDiscountsBenefits, true);

    if (resp.ok) {
        yield put(fetchMyPartnerBenefitsActions.success(resp.data));
    } else {
        yield put(fetchMyPartnerBenefitsActions.success([]));
    }
}

function* watchVoucherBenefits() {
    const resp = yield call(api.getVoucherBenefits);

    if (resp.ok) {
        yield put(fetchVoucherBenefitsActions.success(resp.data));
    } else {
        yield put(fetchVoucherBenefitsActions.success([]));
    }
}

function* watchVipBenefits() {
    const user = yield select(selectUserProfile);
    if (user) {
        const resp = yield call(api.getVIPbenefits);
        if (resp.ok) {
            yield put(fetchVIPBenefitsActions.success(resp.data));
        }
    } else {
        yield put(fetchVIPBenefitsActions.success([]));
    }
}

function* watchCreditBenefits() {
    const resp = yield call(api.getCreditBenefits);

    if (resp.ok) {
        yield put(fetchCreditBenefitsActions.success(resp.data));
    } else {
        yield put(fetchCreditBenefitsActions.success([]));
    }
}

function* watchUserCredit() {
    const profile = yield select(selectUserProfile);

    if (!profile || !profile.id) {
        return;
    }

    const resp = yield call(api.getUserCredits);
    if (resp.ok) {
        yield put(fetchUserCreditsActions.success(Number(resp.data.credit)));
    } else {
        yield put(fetchUserCreditsActions.success(0));
    }
}

function* watchFetchBenefits() {
    yield delay(500);
    yield put(fetchPartnerBenefitsActions.request());
    yield put(fetchMyPartnerBenefitsActions.request());
    yield put(fetchVIPBenefitsActions.request());
    yield put(fetchCreditBenefitsActions.request());
    yield put(fetchVoucherBenefitsActions.request());
    yield put(fetchOrdersActions.request());
    yield put(fetchUserCreditsActions.request());
}

function* watchLoadOrders() {
    const resp = yield call(api.getUserOrders);

    if (resp.ok) {
        yield put(fetchOrdersActions.success(resp.data));
    }
}

export function* personalSaga() {
    yield takeLatest(PersonalActionTypes.FetchBenefits, watchFetchBenefits);

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchOrders, RequestActionTypes.REQUEST),
        watchLoadOrders
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchVIPBenefits, RequestActionTypes.REQUEST),
        watchVipBenefits
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchUserCredits, RequestActionTypes.REQUEST),
        watchUserCredit
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchCreditBenefits, RequestActionTypes.REQUEST),
        watchCreditBenefits
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchPartnerBenefits, RequestActionTypes.REQUEST),
        watchPartnerBenefits
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchMyPartnerBenefits, RequestActionTypes.REQUEST),
        watchMyPartnerBenefits
    );

    yield takeLatest(
        createActionType(PersonalActionTypes.FetchVoucherBenefits, RequestActionTypes.REQUEST),
        watchVoucherBenefits
    );
}
