import { Icon, List, Spin, Tag } from 'antd';
import React, { Fragment, FunctionComponent } from 'react';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import moment from 'moment';

import { ProductSelectionPopulated } from '../../../../../../models/Cart';
import { CartPrice } from '../../../../../../components';
import { i18n } from '../../../../../../services/i18n';
import DiscountBadge from '../../../../../DiscountBadge';
import LocalizedString from '../../../../../LocalizedString';
import { TimeSlotType } from '../../../../../../../features/shopping/ducks';
import { environment } from '../../../../../../../environments/environment';
import { shouldDisplayError } from '../../../../../../utils/isEntryErrorTimeRelated';

interface Props {
    items?: ProductSelectionPopulated[];
    onRemove?(id: string): void;
    onRemoveVoucher?(): void;
}

const CartList: FunctionComponent<Props> = ({ items, onRemove, onRemoveVoucher }) => {
    function renderItem(item: ProductSelectionPopulated, index) {
        function handleRemoveClick() {
            if (item.product.type === 'transaction_voucher_product') {
                onRemoveVoucher && onRemoveVoucher();
            } else {
                onRemove && onRemove(item.id);
            }
        }

        return (
            item.product &&
            item.variant ? (
                <List.Item
                    key={index}
                    actions={
                        item.product.type !== 'card-product'
                            ? [<Icon onClick={handleRemoveClick} key={index} type="delete" />]
                            : [<div key={index} className="empty-icon" />]
                    }
                >
                    <List.Item.Meta
                        title={
                            <Truncate lines={2} ellipsis="...">
                                <LocalizedString value={item.product.name} />{' '}
                                <LocalizedString value={item.product.description} />
                            </Truncate>
                        }
                        description={
                            <Fragment>
                                {item.product.type !== 'card-product' &&
                                    <>
                                        <LocalizedString value={item.variant.name} />{' '}
                                        <LocalizedString value={item.variant.description} />
                                    </>
                                }
                                <div className="small text-muted">
                                    {moment(item.arrival).format('DD.MM.YYYY')}{' '}
                                    {item.timeSlot && item.timeSlot.timeSlotType === TimeSlotType.range && (
                                        <>
                                            {item.timeSlot.time}
                                            {environment.config.showTimeSlotRange && item.timeSlot.timeEnd && ` - ${item.timeSlot.timeEnd}`}
                                        </>
                                    )}
                                </div>
                                <div className="ant-list-item-meta-description-badges">
                                    {item.benefits.map((item, index) => (
                                        <DiscountBadge key={index} benefit={item} />
                                    ))}
                                    {item.timeSlot && !item.timeSlot.available && !item.entryError &&
                                        <Tag className="error-badge text-center" color="red">
                                            {i18n.t('timeSlots.cart.unavailable')}
                                        </Tag>
                                    }
                                    {shouldDisplayError(item.entryError) &&
                                        <Tag className="error-badge text-center" color="red">
                                            {i18n.t(`timeSlots.cart.${item.entryError}`)}
                                        </Tag>
                                    }
                                </div>
                            </Fragment>
                        }
                    />
                    <div className="cart-list-price">
                        <CartPrice
                            discounted={oc(item).discountedPrice(0)}
                            original={oc(item).price(0) as number}
                        />
                    </div>
                </List.Item>
            ) : (<Spin/>)
        );
    }

    return (
        <List
            className="cart-list"
            itemLayout="horizontal"
            dataSource={items}
            renderItem={renderItem}
            locale={{ emptyText: i18n.t('cart.emptyText') }}
        />
    );
};

export default CartList;
