import React, { ReactNode, useMemo } from 'react';
import { oc } from 'ts-optchain';
import cn from 'classnames';

import { ProductSelectionPopulated } from '../../models/Cart';
import { cardAvatar } from '../../../resources/images';
import { Price, TotalPrice } from '..';
import LocalizedString from '../LocalizedString';
import ProductValidity from '../ProductValidity';
import { i18n } from '../../services/i18n';
import { Tag } from 'antd';
import { TimeSlotType } from '../../../features/shopping/ducks';
import { environment } from '../../../environments/environment';
import { shouldDisplayError } from '../../utils/isEntryErrorTimeRelated';

interface Props {
    selection: ProductSelectionPopulated;
    extra?: ReactNode;
    discount?: ReactNode;
    checkout?: boolean;
    onDiscountRemove?: () => void;
    minFormat?: boolean;
}

export default function CartEntry({
    selection,
    extra,
    onDiscountRemove,
    discount,
    checkout,
    minFormat,
}: Props) {
    function renderItemValidity() {
        let time = '';

        if (oc(selection).timeSlot.timeSlotType() === TimeSlotType.range) {
            if (environment.config.showTimeSlotRange && oc(selection).timeSlot.timeEnd()) {
                time = `${oc(selection).timeSlot.time()} - ${oc(selection).timeSlot.timeEnd()}`;
            } else {
                time = oc(selection).timeSlot.time('');
            }
        }

        return (oc(selection).product.tags([]) as any).includes('seasonal') ? (
            i18n.t('cartItem.seasonal')
        ) : (
            <span style={{display: 'block'}}>
                <span dangerouslySetInnerHTML={{ __html: i18n.t('checkout.validity')}} />{' '}
                <b>
                    <ProductValidity
                        from={selection.arrival}
                        unit={oc(selection).product.meta.tokenValidityUnit('')}
                        validityAmount={Number(oc(selection).product.meta.tokenValidityAmount() || 1)}
                        time={time}
                    />
                </b>
            </span>
        );
    }

    const appliedVoucher = useMemo(() => {
        const name = oc(selection).voucherJournal.benefit.name();

        if (!oc(selection).voucherJournal.applied() || !name) {
            return undefined;
        }


        if (typeof name === 'string') {
            return name;
        }

        return <LocalizedString value={name} />;
    }, [selection]);

    return (
        <>
            <div className={cn('cart-entry', { 'cart-entry--checkout': checkout })}>
                <div className="cart-entry__icon">
                    <img src="/mock/skipass/icon.png" alt="" />
                </div>
                <div className="cart-entry__desc">
                    {selection.product && (
                        <h3>
                            <LocalizedString value={selection.product.name} />{' '}
                            <LocalizedString value={selection.product.description} />
                        </h3>
                    )}
                    {(!selection.product || !minFormat) &&
                        <p>
                            <LocalizedString value={selection.variant.name}/>{' '}
                            <LocalizedString value={selection.variant.description}/>,{' '}
                            {renderItemValidity()}
                        </p>
                    }
                    {!!appliedVoucher && (
                        <div className="cart-entry__desc__discount">
                            {i18n.t('cartItem.discount')}{' '}
                            "{appliedVoucher}"{' '}
                            {i18n.t('cartItem.discountApplied')}
                            {onDiscountRemove && (
                                <a onClick={onDiscountRemove}> {i18n.t('cartItem.discountCancel')}</a>
                            )}
                        </div>
                    )}
                    {!appliedVoucher && <p>{discount}</p>}
                    {extra && <p>{extra}</p>}
                </div>

                <div className="cart-entry__price">
                    <h3>
                        <TotalPrice
                            type="negative"
                            withDiscount={<Price value={oc(selection).discountedPrice(0)} />}
                            withoutDiscount={<Price value={oc(selection).price(0)} />}
                            badges={oc(selection).benefits([])}
                            displayDiscount={oc(selection).price(0) !== oc(selection).discountedPrice(0)}
                        />
                    </h3>
                </div>
            </div>
            {selection.timeSlot && !selection.timeSlot.available && !selection.entryError &&
                <Tag className="error-badge text-center" color="red">
                    {i18n.t('timeSlots.cart.unavailable')}
                </Tag>
            }
            {shouldDisplayError(selection.entryError) &&
                <Tag className="error-badge text-center" color="red">
                    {i18n.t(`timeSlots.cart.${selection.entryError}`)}
                </Tag>
            }
        </>
    );
}

CartEntry.LipnoCard = () => {
    return (
        <div className="cart-entry">
            <div className="cart-entry__icon">
                <img src={cardAvatar} alt="" />
            </div>
            <div className="cart-entry__desc">
                <h3>{i18n.t('cartItem.lipnocard.name')}</h3>
                <p>{i18n.t('cartItem.lipnocard.description')}</p>
            </div>
            <div className="cart-entry__price" />
        </div>
    );
};
