import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';

import { AppState } from '../../../../common/models/AppState';
import { i18n } from '../../../../common/services/i18n';
import { Layout, Order, ReferenceWrapper, SideWizard } from '../.././../../common/components';
import { fetchOrdersActions, selectUserOrders } from '../../ducks';
import { createRefs } from '../../../../common/components/ReferenceWrapper';
import ChangeTransactionDateModal from '../../../../common/modals/ChangeTransactionDateModal';
import { startProcessAction, StartProcessAction } from '../../../dateChange/ducks';
import { Transaction } from '../../../../common/models/NewTransaction';

interface DispatchToProps {
    fetchOrders: () => void;
    changeDate: (data: StartProcessAction) => void;
}

interface StateToProps {
    orders: Transaction[];
}

type Props = DispatchToProps & StateToProps;

function OrdersComponent(props: Props) {
    const [refs, setRefs] = useState({});

    useEffect(() => {
        props.fetchOrders();
    }, []);

    useEffect(
        () => {
            setRefs(createRefs(props.orders));
        },
        [props.orders]
    );

    function filterOrdersByMonth() {
        return props.orders.reduce<{ date: string; orders: Transaction[] }[]>((a, c) => {
            const date = moment(c.createdAt).format('MMMM YYYY');
            if (a.some(i => i.date === date)) {
                const entry = a.find(i => i.date === date);
                entry && entry.orders.push(c);
            } else {
                const entry = {
                    date,
                    orders: [c],
                };
                a.push(entry);
            }
            return a;
        }, []);
    }

    return (
        <Layout.Content>
            <ChangeTransactionDateModal />
            <Layout.Container>
                <Row className="orders">
                    <Col xs={0} md={6} className="orders__left">
                        {filterOrdersByMonth().length > 0 && (
                            <SideWizard
                                refs={refs}
                                title={`${i18n.t('personal.orders.overviewTitle')} (${
                                    props.orders.length
                                })`}
                                items={filterOrdersByMonth().map(item => ({
                                    title: item.date,
                                    description: `${oc(item).orders.length(0)} ${i18n.t(
                                        'personal.orders.ordersCount'
                                    )}`,
                                }))}
                            />
                        )}
                    </Col>
                    <Col xs={24} md={18} className="orders__right">
                        {filterOrdersByMonth().length > 0 ? (
                            filterOrdersByMonth().map((item, index) => (
                                <ReferenceWrapper reference={refs[index]} key={index}>
                                    <h3>{item.date}</h3>
                                    {item.orders.map((order, index) => (
                                        <Order
                                            transaction={order}
                                            key={index}
                                            onChangeDate={props.changeDate}
                                        />
                                    ))}
                                </ReferenceWrapper>
                            ))
                        ) : (
                            <h2>{i18n.t('personal.order.empty')}</h2>
                        )}
                    </Col>
                </Row>
            </Layout.Container>
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    fetchOrders: fetchOrdersActions.request,
    changeDate: startProcessAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    orders: selectUserOrders(state),
});

export const Orders = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersComponent);
