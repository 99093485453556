import { oc } from 'ts-optchain';
import { TimeSlotType } from '../../features/shopping/ducks';
import { LipnoCardTypes, Token } from './Token';
import { Price } from './Product';

export type CmsContent = Record<string, string | null>;

export enum TransactionType {
    unknown = 'unknown',
    purchase = 'purchase',
    return = 'return',
}

export enum TransactionStatus {
    new = 'new',
    unpaid = 'unpaid',
    paid = 'paid',
    finished = 'finished',
    failed = 'failed',
    paymentFailed = 'payment_failed',
}

export enum EntryType {
    Product = 'product',
    Card = 'card-product',
    Voucher = 'transaction_voucher_product',
}

export enum EntryMethod {
    Print = 'for-print',
    Prepaid = 'for-exchange-ticket',
    Exchange = 'for-exchange-only',
    Card = 'for-card',
}

export const EntryExchangeMethods: (EntryMethod | undefined)[] = [EntryMethod.Prepaid, EntryMethod.Exchange];

export interface EntryExchange {
    birthday: string; // YYYY-MM-DD
    subType: string;
}

export interface EntryTimeSlot {
    from: string; // HH:mm
    to: string; // HH:mm
}

export interface DryRunAmount {
    base: number;
    club: number;
}

export interface TransactionDryRunBenefit {
    id: string;
    type: string;
    name: string | CmsContent;
    badge?: CmsContent;
    combinable: boolean;
}

export interface EntryVoucherJournal {
    error?: string;
    applied: boolean;
    partiallyApplied: boolean;
    benefit?: TransactionDryRunBenefit;
}

export interface EntryMetaBase {
    cartId: string;
    method: EntryMethod;
    giftTemplate?: boolean;
    exchange: EntryExchange;
    validFrom: string;
    originalValidFrom?: string;
    timeSlot: EntryTimeSlot;
    inclusiveValidity: string[];
    timeSlotType: TimeSlotType;
}

export interface EntryProductBase {
    variantId: string;
    productId: string;
    tags: string[];
    name?: CmsContent; // Only for meta products (card, benefit)
    description?: CmsContent; // Only for meta products (card, benefit)
    meta: {
        tokenValidityUnit: 'days' | 'hours';
        tokenValidityAmount: number;
        timeSlotGroup: string;
        allowDateChange?: boolean;
        ziplineProduct?: boolean;
        seasonal?: boolean;
    };
}

interface EntryCore {
    type: EntryType;
    meta: EntryMetaBase;
    product: EntryProductBase;
    voucher?: string;
    errors: string[];
    currency: 'CZK' | 'EUR';
    benefitsApplied: TransactionDryRunBenefit[];
    voucherJournal?: EntryVoucherJournal;
}

export interface EntryBase extends EntryCore {
    amount: DryRunAmount;
}

export interface TransactionCore {
    entries: EntryCore[];
    user: {
        segments: string[];
        credit: number;
        club: boolean;
    };
    currency: 'CZK' | 'EUR';
}

export interface TransactionBase extends TransactionCore {
    entries: EntryBase[];
    amount: DryRunAmount;
}

export interface EntryExtIdentifiers {
    input: {
        productId: string;
        consumerId: string;
        contractorId?: string;
        poolId?: string;
        articleNo?: string;
        useValidFromDate: boolean;
    };
    output: {
        id: string;
        isPrinted?: boolean;
        isPrepaid?: boolean;
        printedData?: string;
        prepaidData?: string;
        permissionSerialNumber?: string;
        NPOSNO?: number;
        NSERIALNO: number;
        NERRORNO: number;
    };
}

export interface EntryProduct extends EntryProductBase {
    name: CmsContent;
    description: CmsContent;
    variant: CmsContent;
    price: Price;
}

export interface Entry extends EntryCore {
    ticketSystem: EntryExtIdentifiers;
    product: EntryProduct;
    token?: Token;
    userData?: Record<string, string>;
    amount: number;
    tax: number;
    reversed?: boolean;
}

export interface TransactionMeta {
    email?: string;
    additionalVOP?: boolean;
    lang?: string;
    invoice?: {
        pdfURL: string;
        depositVS?: string;
        depositPdfURL?: string;
    };
    receipt?: {
        url: string;
        urlmini: string;
    };
}

export interface Transaction extends TransactionCore {
    _id: string;
    entries: Entry[];
    meta: TransactionMeta;
    amount: number;
    time: string;
    owner?: string;
    status: TransactionStatus;
    origin: 'eshop' | 'ssp';
    stornoOrigin: 'admin';
    salesPoint: string;
    paidWith: string;
    type: TransactionType;
    children?: string[];
    parentId?: string;
    createdAt: string;
}

export interface TransactionDataRequest {
    owner?: string;
    salesPoint?: string;
    paidWith?: B2BPaymentType | 'card';
    meta: {
        email?: string;
        additionalVOP?: boolean;
        lang?: string;
    };
}

export type B2BPaymentType = 'invoice' | 'order';

// -----

export interface TicketItem {
    cartId?: string;
    type: 'exchange-pass' | 'pass' | 'info' | 'no-card-item';
    method: EntryMethod;
    code: string;
    serial?: string;
    name: string;
    variant: string;
    validity: string;
    exhangeType?: LipnoCardTypes;
    token?: Token;
    userName?: string;
    smallTicketInfo: string[];
    isGift?: boolean;
}

// -----

export const getTransactionBenefits = (transaction?: TransactionBase | null): TransactionDryRunBenefit[] => {
    return oc(transaction)
        .entries([])
        .reduce(
            (benefits: TransactionDryRunBenefit[], entry) => {
                entry.benefitsApplied.forEach(badge => {
                    if (!benefits.some(i => i.id === badge.id)) {
                        benefits.push(badge);
                    }
                });

                return benefits;
            },
            []
        );
};
