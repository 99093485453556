import { oc } from 'ts-optchain';

import { AppState } from '../../../common/models/AppState';
import { ProductSelection, ProductSelectionPopulated } from '../../../common/models/Cart';
import { Product } from '../../../common/models/Product';
import {
    createActionCreator,
    createApiActionCreators,
    createReducer,
    removeFromArray,
    replaceInArray,
    RequestActionTypes,
} from '../../../common/utils/redux-helpers';
import { selectProducts, selectShopping, TimeSlotType } from '.';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { Transaction, TransactionBase } from '../../../common/models/NewTransaction';

// Action types
export enum CartActionTypes {
    OpenCart = '@@Cart/OPEN_CART',
    CloseCart = '@@Cart/CLOSE_CART',
    SetCartItems = '@@Cart/SET_CART_ITEMS',
    ResetCartItems = '@@Cart/RESET_CART_ITEMS',
    RemoveFromCart = '@@Cart/REMOVE_FROM_CART',
    ValidateTransaction = '@@Cart/VALIDATE_TRANSACTION',
    UpdateItemAssigment = '@@Cart/UPDATE_ITEM_ASSIGMENT',
    UpdateItemVoucher = '@@Cart/UPDATE_ITEM_VOUCHER',
    UpdateItemIsGift = '@@Cart/UPDATE_ITEM_IS_GIFT',
    UpdateEntryUserInfo = '@@Cart/UPDATE_ENTRY_USER_INFO',
    UpdateEntryTimeSlot = '@@Cart/UPDATE_ENTRY_TIME_SLOT',
    ResetCart = '@@Cart/RESET_CART',
    GetCartProducts = '@@Cart/GET_CART_PRODUCTS',
    RemoveTransactionVoucher = '@@Cart/REMOVE_TRANSACTION_VOUCHER',
    AddTransactionVoucher = '@@Cart/ADD_TRANSACTION_VOUCHER',
}

// Action creators
export const setCartItemsAction = createActionCreator(CartActionTypes.SetCartItems);
export const resetCartAction = createActionCreator(CartActionTypes.ResetCart);
export const resetCartItemsAction = createActionCreator(CartActionTypes.ResetCartItems);
export const openCartAction = createActionCreator(CartActionTypes.OpenCart);
export const closeCartAction = createActionCreator(CartActionTypes.CloseCart);
export const removeItemFromCartAction = createActionCreator(CartActionTypes.RemoveFromCart);
export const updateItemAssigmentAction = createActionCreator(CartActionTypes.UpdateItemAssigment);
export const updateItemVoucherAction = createActionCreator(CartActionTypes.UpdateItemVoucher);
export const updateItemIsGiftAction = createActionCreator(CartActionTypes.UpdateItemIsGift);
export const updateEntryUserInfoAction = createActionCreator(CartActionTypes.UpdateEntryUserInfo);
export const updateEntryTimeSlotAction = createActionCreator(CartActionTypes.UpdateEntryTimeSlot);
export const validateTransactionActions = createApiActionCreators(
    CartActionTypes.ValidateTransaction
);
export const getCartProductsActions = createApiActionCreators(CartActionTypes.GetCartProducts);

export const removeTransactionVoucherAction = createActionCreator(
    CartActionTypes.RemoveTransactionVoucher
);

export const addTransactionVoucherAction = createActionCreator(
    CartActionTypes.AddTransactionVoucher
);

// Reducers
export interface CartState {
    open: boolean;
    transaction: TransactionBase | null;
    items: ProductSelection[];
    credit: number;
    products: Product[];
    transactionVouchers: string[];
}

const initialState: CartState = {
    items: [],
    open: false,
    transaction: null,
    credit: 0,
    products: [],
    transactionVouchers: [],
};

export const cart = createReducer(initialState, {
    [CartActionTypes.ResetCartItems]: (state: CartState) => ({ ...state, items: [] }),
    [CartActionTypes.OpenCart]: (state: CartState) => ({ ...state, open: true }),
    [CartActionTypes.CloseCart]: (state: CartState) => ({ ...state, open: false }),
    [CartActionTypes.RemoveFromCart]: (state: CartState, payload: string) => ({
        ...state,
        items: removeFromArray(state.items, item => item.id === payload),
    }),
    [CartActionTypes.SetCartItems]: (state: CartState, payload) => ({
        ...state,
        items: [...state.items, ...payload],
    }),
    [CartActionTypes.ValidateTransaction]: {
        [RequestActionTypes.SUCCESS]: (
            state: CartState,
            payload: { credit: number; transaction: Transaction }
        ) => ({
            ...state,
            ...payload,
        }),
    },
    [CartActionTypes.UpdateItemAssigment]: (state: CartState, { id, assigment }) => ({
        ...state,
        items: replaceInArray(state.items, i => i.id === id, {
            ...state.items.find(i => i.id === id),
            assigment,
        }),
    }),
    [CartActionTypes.UpdateItemVoucher]: (state: CartState, { id, voucher }) => ({
        ...state,
        items: replaceInArray(state.items, i => i.id === id, {
            ...state.items.find(i => i.id === id),
            voucher,
        }),
    }),
    [CartActionTypes.UpdateItemIsGift]: (state: CartState, { id, isGift }) => ({
        ...state,
        items: replaceInArray(state.items, i => i.id === id, {
            ...state.items.find(i => i.id === id),
            isGift,
        }),
    }),
    [CartActionTypes.UpdateEntryUserInfo]: (state: CartState, { id, data }) => ({
        ...state,
        items: replaceInArray(state.items, i => i.id === id, {
            ...state.items.find(i => i.id === id),
            userData: data,
        }),
    }),
    [CartActionTypes.UpdateEntryTimeSlot]: (state: CartState, data: {[id: string]: string}) => ({
        ...state,
        items: state.items.map(i => {
            if (i.id && data[i.id]) {
                return {
                    ...i,
                    time: data[i.id],
                    timeEnd: data[i.id] !== '00:00'
                        ? moment(data[i.id], 'HH:mm')
                            .add(30, 'minutes')
                            .format('HH:mm')
                        : undefined
                };
            }

            return i;
        })
    }),
    [CartActionTypes.ResetCart]: (_state: CartState) => initialState,
    [CartActionTypes.GetCartProducts]: {
        [RequestActionTypes.SUCCESS]: (state: CartState, payload: Product[]) => ({
            ...state,
            products: payload,
        }),
    },
    [CartActionTypes.AddTransactionVoucher]: (state, payload) => ({
        ...state,
        transactionVouchers: payload,
    }),
    [CartActionTypes.RemoveTransactionVoucher]: (state) => ({
        ...state,
        transactionVouchers: initialState.transactionVouchers,
    }),
});

// Selectors
export function selectCart(state: AppState) {
    return selectShopping(state).cart;
}
export function selectCartProducts(state: AppState) {
    return selectCart(state).products;
}

export const selectCartVisibility = (state: AppState) => {
    return selectCart(state).open;
};

export const selectCartItems = (state: AppState) => {
    return selectCart(state).items;
};

export function selectCartIsEmpty(state: AppState) {
    return selectCartItems(state).length === 0;
}

export function selectCartTransaction(state: AppState) {
    return selectCart(state).transaction;
}

export function selectCartTransactionEntries(state: AppState) {
    return oc(selectCartTransaction(state)).entries([]);
}

export const selectEmailStatus = (state: AppState) => {
    return selectShopping(state).order.email;
};

export function selectSomeItemNotAssigned(state: AppState): boolean {
    return selectCartItems(state).some(item => !item.assigment);
}

export const selectCartItemsCount = (state: AppState): number => {
    return selectCartItems(state).length;
};

export function selectCartPopulatedItems(state: AppState): ProductSelectionPopulated[] {
    const transactionEntries = selectCartTransactionEntries(state);

    const cartItems = selectCartItems(state).map(item => {
        let product = selectProducts(state).find(p => p._id === item.product) as Product;

        if (!product) {
            product = selectCartProducts(state).find(p => p._id === item.product) as Product;
        }

        const variant = oc(product)
            .variants([])
            .find(p => p._id === item.variant) as Product;

        if (!product || !variant) {
            console.warn('Unable to load product or variant');
            console.warn(product);
            console.warn(variant);
        }

        const entry = transactionEntries.find(entry => entry.meta.cartId === item.id);

        const inHistory = oc(entry).meta.timeSlot.from()
            ? moment(`${moment(item.arrival).format('YYYY-MM-DD')} ${oc(entry).meta.timeSlot.from()}`, 'YYYY-MM-DD HH:mm')
                .isBefore(
                    oc(entry).meta.timeSlotType() === TimeSlotType.day
                    ? moment().startOf('day')
                    : moment().add(environment.project === 'morava' ? -30 : 15, 'minutes')
                )
            : moment(item.arrival).isBefore(moment(), 'day');

        return {
            id: item.id,
            product,
            variant,
            arrival: item.arrival,
            inclusiveValidity: item.inclusiveValidity,
            timeSlot: oc(entry).meta.timeSlot() ?
                {
                    ziplineProduct: oc(entry).product.meta.ziplineProduct(),
                    time: oc(entry).meta.timeSlot.from(),
                    timeEnd: oc(entry).meta.timeSlot.to(),
                    timeSlotType: oc(entry).meta.timeSlotType(),
                    timeSlotGroup: oc(entry).product.meta.timeSlotGroup(),
                    available: !oc(entry).errors([]).includes('time-filled'),
                } :
                null,
            entryError: inHistory ? 'dateInHistory' : oc(entry).errors[0](),
            price: oc(entry).amount.base(0),
            discountedPrice: oc(entry).amount.club(0),
            assigment: item.assigment,
            voucher: item.voucher,
            isGift: item.isGift,
            benefits: oc(entry).benefitsApplied([]),
            voucherJournal: oc(entry).voucherJournal(),
            userData: item.userData,
        } as ProductSelectionPopulated;
    });

    return [
        ...cartItems,
        ...selectCartTransactionEntries(state)
            .filter(e => ['card-product', 'transaction_voucher_product'].includes(e.type))
            .map((e) => ({
                id: e.meta.cartId,
                product: {
                    _id: e.product.productId,
                    type: e.type,
                    name: e.product.name || {},
                },
                variant: {
                    _id: e.product.productId,
                    type: e.type,
                    name: e.product.name || {},
                },
                arrival: e.meta.validFrom,
                price: e.amount.base,
                discountedPrice: e.amount.club,
                benefits: []
            })),
    ];
}

export function selectCartDiscountPrice(state: AppState) {
    return oc(selectCartTransaction(state)).amount.club() as number;
}

export function selectCartBaseTotal(state: AppState) {
    return oc(selectCartTransaction(state)).amount.base() as number;
}
