import moment from 'moment';
import { oc } from 'ts-optchain';

import { ProductSelection } from '../models/Cart';
import { apiClient } from '../services/api-client';
import { getSubTypeFromBirthday } from '../utils/getSubTypeFromBirthday';
import { TransactionDataRequest } from '../models/NewTransaction';

const getExchangeInfo = (birthday?: string) => {
    if (!birthday) {
        return undefined;
    }

    return {
        exchangeBirthday: moment(birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        exchangeSubType: getSubTypeFromBirthday(birthday),
    };
};

export const transactionsApi = {
    dryRun: (
        cartItems: ProductSelection[],
        transactionVouchers?: string[],
        personal?: boolean,
        owner?: string,
    ) =>
        apiClient.post(
            `/v2/transaction/dry-run`,
            {
                origin: 'eshop',
                entries: cartItems.map((item) => ({
                    cartId: item.id,
                    variantId: item.variant,
                    voucher:  oc(item).voucher(),
                    tokenId: oc(item).assigment.token._id(),
                    method: oc(item).assigment.method(),
                    validFrom: `${moment(item.arrival).format('YYYY-MM-DD')}`,
                    timeSlot: item.time || undefined,
                    isGift: oc(item).isGift() || undefined,
                })),
                transactionBenefits: transactionVouchers,
                owner,
            },
            { params: { personal } }
        ),
    validate: (
        cartItems: ProductSelection[],
        transactionVouchers: string[],
        data: TransactionDataRequest,
    ) =>
        apiClient.post(
            `/v2/transaction/validate`,
            {
                origin: 'eshop',
                entries: cartItems.map((item) => ({
                    cartId: item.id,
                    variantId: item.variant,
                    voucher: oc(item).voucher(),
                    userData: oc(item).userData(),
                    tokenId: oc(item).assigment.token._id(),
                    method: oc(item).assigment.method(),
                    ...getExchangeInfo(oc(item).assigment.exchangeBirthday()),
                    validFrom: `${moment(item.arrival).format('YYYY-MM-DD')}`,
                    timeSlot: item.time || undefined,
                    timeSlotEnd: item.timeEnd || undefined,
                    isGift: oc(item).isGift() || undefined,
                })),
                transactionBenefits: transactionVouchers,
                status: 'new',
                ...data,
            },
        ),
    transactionPayment: (id: string, successUrl: string, errorUrl: string, lang: string) => {
        return apiClient.post(`/payment/url/${id}`, {
            successUrl,
            errorUrl,
            lang,
        });
    },
    transactionLoad: (id: string) => apiClient.get(`/transactions/${id}`),
};
