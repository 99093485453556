import React, { useEffect, useState } from 'react';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Divider, Row } from 'antd';

import { Button, Modal, Select } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import { ProductSelectionPopulated } from '../../models/Cart';
import LocalizedString from '../../components/LocalizedString';
import ProductValidity from '../../components/ProductValidity';
import { AppState } from '../../models/AppState';
import {
    getTimeSlotDailyOptionsActions,
    selectTimeSlotsDailyTimes,
    TimeSlotTime,
    TimeSlotType
} from '../../../features/shopping/ducks';
import { removeItemFromCartAction, updateEntryTimeSlotAction } from '../../../features/shopping/ducks/cart';
import _ from 'lodash';
import { environment } from '../../../environments/environment';

interface Props {
    items?: ProductSelectionPopulated[];
    visible?: boolean;
}

const ChangeTimeslotTime = ({ items, visible, ...props }: Props & StateToProps & DispatchToProps) => {
    const [selectedDates, setSelectedDates] = useState<{[key: string]: string}>({});
    const [occupiedSlots, setOccupiedSlots] = useState<Record<string, number>>({});

    useEffect(() => {
        if (items && visible) {
            const dates = items
                .filter(i => oc(i).timeSlot.timeSlotType() === TimeSlotType.range)
                .reduce((acc, item) => {
                    const days = oc(item).product.meta.tokenValidityUnit() === 'days'
                        ? Number(oc(item).product.meta.tokenValidityAmount('1'))
                        : 1;

                    return {
                        ...acc,
                        [oc(item).timeSlot.timeSlotGroup('')]: [
                            ...new Set([
                                ...(acc[oc(item).timeSlot.timeSlotGroup('')] || []),
                                ...new Array(days)
                                    .fill(1)
                                    .map((d, i) => moment(item.arrival).add(i, 'days').format('YYYY-MM-DD'))
                            ])
                        ]
                    };
                }, {});

            props.searchTimeSlotsForDates(dates);
        }
    }, [visible]);

    const isItemSlotOccupied = (groupId: string, date: string, slot: string, item: ProductSelectionPopulated) => {
        const times = getDateTimeAvailable(groupId, date, slot, oc(item).inclusiveValidity([]));

        if (!times) {
            return false;
        }

        return times.available > times.occupied;
    };

    const getDateTimeAvailable = (groupId: string, date: string, time: string, inclusiveValidity: string[]) => {
        const times = _.cloneDeep(props.dailyTimeSlots[`${groupId}|${date}`][time]);

        if (!times) {
            return null;
        }

        for (const d of inclusiveValidity) {
            if (!props.dailyTimeSlots[`${groupId}|${d}`][time]) {
                continue;
            }

            const newAvailable = props.dailyTimeSlots[`${groupId}|${d}`][time].available;
            const newOccupied = props.dailyTimeSlots[`${groupId}|${d}`][time].occupied + oc(occupiedSlots)[`${groupId}|${d}|${time}`](0);

            if ((times.available - times.occupied) > (newAvailable - newOccupied)) {
                times.available = newAvailable;
                times.occupied = newOccupied;
            }
        }

        return times;
    };

    const getTimeOptionsForEntry = (item: ProductSelectionPopulated) => {
        const group = oc(item).timeSlot.timeSlotGroup('');
        const date = moment(item.arrival).format('YYYY-MM-DD');

        if (!props.dailyTimeSlots || !props.dailyTimeSlots[`${group}|${date}`]) {
            return [];
        }

        return Object.keys(props.dailyTimeSlots[`${group}|${date}`])
            .filter(slot => isItemSlotOccupied(group, date, slot, item))
            .filter(slot => !moment(`${date} ${slot}`, 'YYYY-MM-DD HH:mm')
                .isBefore(moment().add(environment.project === 'morava' ? -30 : 15, 'minutes')))
            .map(slot => {
                const available = getDateTimeAvailable(group, date, slot, oc(item).inclusiveValidity([]));

                return {
                    label: `${slot} (${available ? Math.max(available.available - available.occupied, 0) : 0} ${i18n.t('timeSlotsChangeTime.modal.free')})`,
                    value: slot,
                };
            });
    };

    const handleEntrySelectChange = (item: ProductSelectionPopulated, time) => {
        const date = moment(item.arrival).format('YYYY-MM-DD');

        const occupied = _.cloneDeep(occupiedSlots);

        for (const d of oc(item).inclusiveValidity([])) {
            if (selectedDates[item.id]) {
                const oldTime = selectedDates[item.id].split('T')[1];
                const key = `${oc(item).timeSlot.timeSlotGroup('')}|${d}|${oldTime}`;
                occupied[key] = Math.max((occupied[key] || 0) - 1, 0);
            }

            const newKey = `${oc(item).timeSlot.timeSlotGroup('')}|${d}|${time}`;
            occupied[newKey] = Math.max((occupied[newKey] || 0) + 1, 0);
        }

        setOccupiedSlots(occupied);
        setSelectedDates({
            ...selectedDates,
            [item.id]: `${date}T${time}`,
        });
    };

    const handleSubmitChanges = () => {
        if (items && items.some(i => oc(i).timeSlot.timeSlotType() === TimeSlotType.range && !selectedDates[i.id])) {
            return;
        }

        oc(items)([])
            .filter(i =>
                oc(i).timeSlot.timeSlotType() !== TimeSlotType.range &&
                (!!oc(i).entryError() || !oc(i).timeSlot.available())
            )
            .forEach(i => props.removeFromCartList(i.id));

        props.updateEntryTimeSlots(
            Object.keys(selectedDates)
                .reduce((acc, key) => ({
                    ...acc,
                    [key]: selectedDates[key].split('T')[1]
            }), {})
        );
    };

    return (
        <Modal
            visible={visible}
            width={ModalSizes.large}
            title={i18n.t('timeSlotsChangeTime.modal.title')}
            footer={null}
            closable={false}
        >
            <div className="time-slot-time-modal">
                <Row>
                    <Col span={24} className="time-slot-time-modal__intro">
                        {i18n.t('timeSlotsChangeTime.modal.intro')}
                    </Col>
                </Row>
                {items && items
                    .filter(i => i.timeSlot || i.entryError)
                    .map((i, index) => (
                        <React.Fragment key={i.id}>
                            <div className="cart-entry">
                                <div className="cart-entry__desc">
                                    {i.product && (
                                        <h3>
                                            <LocalizedString value={i.product.name} /><br/>
                                        </h3>
                                    )}
                                    <p>
                                        <LocalizedString value={i.variant.name} />{' '}
                                        {i.userData &&
                                            `(${i.userData.firstName} ${i.userData.lastName})`
                                        }
                                    </p>
                                    <p>
                                        {i18n.t('checkout.validity')}{' '}
                                        <ProductValidity
                                            from={i.arrival}
                                            unit={oc(i).product.meta.tokenValidityUnit('')}
                                            validityAmount={Number(oc(i).product.meta.tokenValidityAmount() || 1)}
                                        />
                                    </p>
                                </div>
                                <div className="cart-entry__time">
                                    {oc(i).timeSlot.timeSlotType() === TimeSlotType.range
                                        ? <Select
                                            onChange={(time) => handleEntrySelectChange(i, time)}
                                            placeholder={i18n.t('timeSlotsChangeTime.modal.timeSelect.placeholder')}
                                            value={selectedDates[i.id] && selectedDates[i.id].split('T')[1]}
                                            options={getTimeOptionsForEntry(i)}
                                            style={{ width: '180px' }}
                                        />
                                        : <div className="cart-entry__time--removed">
                                            {i18n.t('timeSlotsChangeTime.modal.removedFromCart')}
                                        </div>
                                    }
                                </div>
                            </div>
                            {index !== items.length - 1 &&
                                <Divider/>
                            }
                        </React.Fragment>
                    ))
                }
                <Button
                    disabled={items && items.some(i => oc(i).timeSlot.timeSlotType() === TimeSlotType.range && !selectedDates[i.id])}
                    onClick={handleSubmitChanges}
                    className="time-slot-time-modal__submit"
                >
                    {i18n.t('timeSlotsChangeTime.modal.submit')}
                </Button>
            </div>
        </Modal>
    );
};

interface DispatchToProps {
    removeFromCartList(id: string);
    searchTimeSlotsForDates(dates: Record<string, string[]>): void;
    updateEntryTimeSlots(dates: { [key: string]: string }): void;
}

interface StateToProps {
    dailyTimeSlots: Record<string, Record<string, TimeSlotTime>>;
}

const mapDispatchToProps: DispatchToProps = {
    removeFromCartList: removeItemFromCartAction,
    searchTimeSlotsForDates: getTimeSlotDailyOptionsActions.request,
    updateEntryTimeSlots: updateEntryTimeSlotAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    dailyTimeSlots: selectTimeSlotsDailyTimes(state),
});

export const ChangeTimeslotTimeModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeTimeslotTime);
