import { Col, Divider, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import * as Yup from 'yup';

import { AppState } from '../../../../common/models/AppState';
import { UserEntryFormValues, UserProfile } from '../../../../common/models/User';
import { i18n } from '../../../../common/services/i18n';
import {
    cardVerifyResetAction,
    loadCardsActions,
    registerShoppingAsyncAction,
    selectIsLogged,
    selectUserProfile,
} from '../../../user/ducks';
import {
    Button,
    CheckBox,
    Input,
    Layout,
    LocalizedString,
    PageDescription,
    Price,
    ProductAssigner,
    SideWizard,
    TotalPrice,
} from '../.././../../common/components';
import { ProductSelectionPopulated } from '../../../../common/models/Cart';
import { Subheader } from '../../components';
import {
    ProductVoucherValidation,
    resetValidateProductVoucherAction,
    selectEshopConfig,
    selectProductVoucherValidation,
    validateProductVoucherAction,
} from '../../ducks';
import {
    removeItemFromCartAction,
    removeTransactionVoucherAction,
    selectCartBaseTotal,
    selectCartDiscountPrice,
    selectCartIsEmpty,
    selectCartPopulatedItems,
    selectSomeItemNotAssigned,
    updateEntryUserInfoAction,
    updateItemVoucherAction,
} from '../../ducks/cart';
import { setAsyncUserIdAction, setConfirmationEmailAction } from '../../ducks/order';
import ReferenceWrapper, { createRefs } from '../../../../common/components/ReferenceWrapper';
import { environment } from '../../../../environments/environment';
import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';
import { Config } from '../../../../common/models/Config';
import { getSubTypeFromBirthday } from '../../../../common/utils/getSubTypeFromBirthday';
import InvalidProductsModal from '../../../../common/modals/InvalidProductsModal';
import { EntryExchangeMethods, EntryMethod } from '../../../../common/models/NewTransaction';
import { needUserInfoForEntry } from '../../../../common/utils/needUserInfoForEntry';

interface DispatchToProps {
    removeFromCartList(id: string);
    removeTransactionVoucher();
    updateVoucher(payload: { id: string; voucher: string });
    updateEntryUserInfo(payload: { id: string; data: UserEntryFormValues });
    resetVerifyCard: () => void;
    validateVoucher: (payload: { selection: ProductSelectionPopulated; voucher: string }) => void;
    resetValidateVoucher: () => void;
    setConfirmEmail: (email: string) => void;
    registerAsync: (data: {email: string; data?: UserEntryFormValues}) => void;
    resetUserId: () => void;
    fetchCards: () => void;
}

interface StateToProps {
    items: ProductSelectionPopulated[];
    isLoggedIn: boolean;
    total: number;
    totalDiscounted: number;
    cartEmpty: boolean;
    user: UserProfile;
    someNotAssigned: boolean;
    voucherValidation: ProductVoucherValidation;
    config: Config | null;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

function InformationsComponent(props: Props) {
    const [agreements, setAgreements] = useState(false);
    const [additionalVOP, setAdditionalVOP] = useState(false);
    const [registration, setRegistration] = useState(false);
    const [email, setEmail] = useState(selectEmailDefaultValue());
    const [refs, setRefs] = useState({});

    useEffect(() => {
        props.resetVerifyCard();
        props.fetchCards();

        sendGtmEvent({
            event: 'page_load',
            page: 'informations',
            products: props.items.map(item => item.product.name.cz),
        });
    }, []);

    function handleAgreementsChange(e: CheckboxChangeEvent) {
        setAgreements(e.target.checked);
    }

    function handleRegistrationChange(e: CheckboxChangeEvent) {
        setRegistration(e.target.checked);
    }

    function handleAdditionalVOPChange(e: CheckboxChangeEvent) {
        setAdditionalVOP(e.target.checked);
    }

    function handleContinueClick() {
        props.setConfirmEmail(email ? email : '');

        if (registration && email) {
            const user = props.items.find(i => !!i.userData && i.userData.email === email);

            props.registerAsync({email, data: user && user.userData});
        } else if (!registration) {
            props.resetUserId();
        }

        props.history.push('/shopping/checkout');
    }

    function handleEmailChangge(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value.trim());
    }

    function handleProductAssigmentChange(id: string, voucher: string) {
        props.updateVoucher({ id, voucher });
    }

    function handleAddEntryUserInfo(id: string, data: UserEntryFormValues) {
        props.updateEntryUserInfo({ id, data });
    }

    function selectEmailDefaultValue() {
        return props.isLoggedIn ? props.user.email : undefined;
    }

    function selectCanContinue() {
        return email && agreements && !props.someNotAssigned && (!oc(props.config).additionalVOP(false) || additionalVOP);
    }

    function hasItemAssigment(item: ProductSelectionPopulated) {
        if (!item.assigment) {
            return false;
        }

        if (item.assigment.method === EntryMethod.Card) {
            return !!item.assigment.token;
        }

        if (
            EntryExchangeMethods.includes(item.assigment.method as EntryMethod) &&
            environment.config.exchangeTicketCardBirthday
        ) {
            const subType = getSubTypeFromBirthday(item.assigment.exchangeBirthday);

            return subType && item.assigment.exchangeBirthday && oc(item).variant.tags([]).includes(subType);
        }

        return true;
    }

    function isContinueDisabled() {
        return !Yup.string().required().email().isValidSync(email) ||
            !selectCanContinue() ||
            (
                props.items &&
                props.items
                    .filter(e => e.product.type === 'PRODUCT')
                    .some(e => !hasItemAssigment(e) || (needUserInfoForEntry(e) && !e.userData))
            );
    }

    useEffect(
        () => {
            setRefs(createRefs(props.items));
        },
        [props.items]
    );

    return (
        <Layout.Content>
            {props.cartEmpty && <Redirect to="/" />}
            <InvalidProductsModal type="invalid-segment" />
            <Subheader />
            <Layout.Container>
                <PageDescription
                    title={i18n.t('informations.title')}
                    description={i18n.t('informations.description')}
                />
                <Row className="informations">
                    <Col xs={0} md={6} className="informations__left">
                        <SideWizard
                            key={refs.toString()}
                            refs={refs}
                            items={props.items.map(item => ({
                                title: (
                                    <Truncate>
                                        <LocalizedString value={item.product.name} />
                                    </Truncate>
                                ),
                                description: <LocalizedString value={item.variant.description} />,
                            }))}
                            title={`${i18n.t('informations.itemsCount')}: ${props.items.length}`}
                        />
                    </Col>
                    <Col xs={24} md={18} className="informations__right">
                        {props.items.length
                            ? props.items.map((item, index) => (
                                  <ReferenceWrapper reference={refs[index]} key={item.id}>
                                      <ProductAssigner
                                          key={index}
                                          selection={item}
                                          voucherValidation={props.voucherValidation}
                                          onRemove={props.removeFromCartList}
                                          onDeleteTransactionVoucher={props.removeTransactionVoucher}
                                          onVoucherChange={handleProductAssigmentChange}
                                          onAddEntryUserInfo={handleAddEntryUserInfo}
                                          onValidateVoucher={props.validateVoucher}
                                          onResetValidateVoucher={props.resetValidateVoucher}
                                      />
                                  </ReferenceWrapper>
                              ))
                            : 'No items to show'}
                        <Row type="flex" align="middle">
                            <Col xs={24} md={12}>
                                <Input
                                    error={email !== undefined && !Yup.string().required().email().isValidSync(email)}
                                    value={email}
                                    onChange={handleEmailChangge}
                                    placeholder={i18n.t('informations.email.placeholder')}
                                    label={i18n.t('informations.email.label')}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <TotalPrice
                                    withDiscount={<Price value={props.totalDiscounted} />}
                                    withoutDiscount={
                                        <Fragment>
                                            {i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                            <Price value={props.total} />
                                        </Fragment>
                                    }
                                    displayDiscount={props.totalDiscounted !== props.total}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <CheckBox
                            value={agreements}
                            onChange={handleAgreementsChange}
                            label={
                                <span dangerouslySetInnerHTML={{__html: i18n.t('informations.terms')}}/>
                            }
                        />
                        {environment.config.registrationCheckbox &&
                            !props.isLoggedIn &&
                            props.items.some(e => !!e.timeSlot && !!e.timeSlot.time && e.timeSlot.ziplineProduct) &&
                            <CheckBox
                                value={registration}
                                onChange={handleRegistrationChange}
                                label={i18n.t('informations.autoRegistration')}
                            />
                        }
                        {props.config && props.config.additionalVOP &&
                            <CheckBox
                                value={additionalVOP}
                                onChange={handleAdditionalVOPChange}
                                htmlLabel={i18n.t('informations.additionalVOP')}
                            />
                        }
                        <Button
                            block
                            type="success"
                            disabled={isContinueDisabled()}
                            onClick={handleContinueClick}
                        >
                            {i18n.t('informations.continue')}
                        </Button>
                    </Col>
                </Row>
            </Layout.Container>
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    removeFromCartList: removeItemFromCartAction,
    updateVoucher: updateItemVoucherAction,
    updateEntryUserInfo: updateEntryUserInfoAction,
    resetVerifyCard: cardVerifyResetAction,
    validateVoucher: validateProductVoucherAction.request,
    resetValidateVoucher: resetValidateProductVoucherAction,
    setConfirmEmail: setConfirmationEmailAction,
    registerAsync: registerShoppingAsyncAction.request,
    resetUserId: setAsyncUserIdAction,
    removeTransactionVoucher: removeTransactionVoucherAction,
    fetchCards: loadCardsActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    items: selectCartPopulatedItems(state),
    total: selectCartBaseTotal(state),
    totalDiscounted: selectCartDiscountPrice(state),
    isLoggedIn: selectIsLogged(state),
    cartEmpty: selectCartIsEmpty(state),
    user: selectUserProfile(state),
    someNotAssigned: selectSomeItemNotAssigned(state),
    voucherValidation: selectProductVoucherValidation(state),
    config: selectEshopConfig(state),
});

export const Informations = connect(
    mapStateToProps,
    mapDispatchToProps
)(InformationsComponent);
