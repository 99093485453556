import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { oc } from 'ts-optchain';

import { ProductSelectionPopulated } from '../../../../models/Cart';
import {
    closeCartAction,
    openCartAction,
    removeItemFromCartAction, removeTransactionVoucherAction,
    selectCartBaseTotal,
    selectCartDiscountPrice,
    selectCartIsEmpty,
    selectCartPopulatedItems,
    selectCartVisibility,
} from '../../../../../features/shopping/ducks/cart';
import { Button, Drawer, Price } from '../../../../components';
import { AppState } from '../../../../models/AppState';

import { CartList, CartSummary } from './components';
import { environment } from '../../../../../environments/environment';

const DRAWER_WIDTH = '30%';

interface DispatchToProps {
    openCart?();
    closeCart?();
    removeItemFromCart?(id: string);
    removeTransactionVoucher();
}

interface StateToProps {
    isEmpty: boolean;
    total?: number;
    totalDiscounted?: number;
    visible?: boolean;
    items?: ProductSelectionPopulated[];
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

const Cart: FunctionComponent<Props> = ({
    total = 0,
    totalDiscounted = 0,
    closeCart,
    openCart,
    removeItemFromCart,
    removeTransactionVoucher,
    visible,
    items,
    history,
    isEmpty,
}) => {
    function handleCartClick() {
        if (visible) {
            closeCart && closeCart();
        } else {
            openCart && openCart();
        }
    }

    function handleContinueClick() {
        closeCart && closeCart();
    }

    function handlePayClick() {
        closeCart && closeCart();
        history.push(environment.config.cartButtonUrl);
    }

    function handleRemoveClick(id: string) {
        removeItemFromCart && removeItemFromCart(id);
    }

    function handleRemoveVoucherClick() {
        removeTransactionVoucher && removeTransactionVoucher();
    }

    return (
        <Drawer
            className="cart-drawer"
            visible={visible}
            onClose={handleCartClick}
            width={DRAWER_WIDTH}
            title={
                <>
                    <Trans i18nKey="cart.title" /> ({oc(items).length(0)})
                </>
            }
        >
            <div className="cart">
                <div className="cart-content">
                    <CartList items={items} onRemove={handleRemoveClick} onRemoveVoucher={handleRemoveVoucherClick} />
                </div>
                <div className="cart-footer">
                    <CartSummary
                        price={<Price value={total} />}
                        priceWithDiscount={<Price value={totalDiscounted} />}
                        displayDiscount={total !== totalDiscounted}
                    />
                    <Button
                        disabled={isEmpty || oc(items).length(0) > environment.config.productCountLimit}
                        block
                        type="success"
                        size="large"
                        onClick={handlePayClick}
                    >
                        <Trans i18nKey="cart.pay" />
                    </Button>
                    <Button block size="large" type="primary" onClick={handleContinueClick}>
                        <Trans i18nKey="cart.countinueInShopping" />
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: AppState): StateToProps => ({
    total: selectCartBaseTotal(state),
    visible: selectCartVisibility(state),
    items: selectCartPopulatedItems(state),
    totalDiscounted: selectCartDiscountPrice(state),
    isEmpty: selectCartIsEmpty(state),
});

const mapDispatchToProps: DispatchToProps = {
    openCart: openCartAction,
    closeCart: closeCartAction,
    removeItemFromCart: removeItemFromCartAction,
    removeTransactionVoucher: removeTransactionVoucherAction,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Cart)
);
