import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { i18n } from '../../../../services/i18n';
import { Button, Input, LocalizedString } from '../../../../components';
import { DatePicker } from 'antd';
import moment from 'moment';
import { AppState } from '../../../../models/AppState';
import {
    selectActiveItem,
    selectActiveProduct,
    selectActiveStep,
    selectActiveUpdateInfo,
    UpdateInfo,
    updateItemValidityAction,
    UpdateValidityAction,
} from '../../../../../features/dateChange/ducks';
import TimeCarousel
    from '../../../../../features/shopping/features/eshop/components/ProductSelect/components/TimeCarousel';
import { oc } from 'ts-optchain';
import { Product } from '../../../../models/Product';
import { environment } from '../../../../../environments/environment';
import { isProductAndSelectionValidAtDate } from '../../../../utils/visibility-helpers';
import { resetTimeSlotOptionsAction } from '../../../../../features/shopping/ducks';
import { Entry } from '../../../../models/NewTransaction';

interface Props {
    total: number;
    onBackClick: () => void;
    onNextClick: () => void;
}

const Step = ({
    activeStep,
    total,
    item,
    updateData,
    updateValidity,
    resetTimeSlots,
    activeProduct,
    onBackClick,
    onNextClick
}: Props & StateToProps & DispatchToProps) => {
    const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
    const [validFrom, setValidFrom] = useState<string>('');
    const [timeSlot, setTimeSlot] = useState<string>('');

    if (!item) {
        return <></>;
    }

    useEffect(() => {
        setValidFrom(updateData ? updateData.validFrom || '' : '');
        setTimeSlot(updateData ? updateData.timeSlot || '' : '');
    }, [updateData]);

    const isNextAllowed = useMemo(() => {
        if (activeProduct && activeProduct.timeSlotGroup) {
            return !updateData || (!!validFrom && !!timeSlot);
        }

        return true;
    }, [activeProduct, updateData, validFrom, timeSlot]);

    const getInclusiveValidity = (date) => {
        const dayCount = oc(activeProduct).meta.tokenValidityUnit() === 'days'
            ? Number(oc(activeProduct).meta.tokenValidityAmount('1'))
            : 1;

        return new Array(dayCount)
            .fill(1)
            .map((_, i) => moment(date).add(i, 'days').format('YYYY-MM-DD'));
    };

    const validFromVal = useMemo(() =>
        validFrom
            ? moment(validFrom, 'YYYY-MM-DD')
            : moment(item.meta.validFrom),
    [validFrom, item]);

    const timeSlotVal = useMemo(() => {
        if (
            validFromVal.format('YYYY-MM-DD') !== moment(item.meta.validFrom).format('YYYY-MM-DD') ||
            validFrom
        ) {
            return timeSlot;
        }

        return timeSlot || oc(item).meta.timeSlot.from('');
    }, [item, validFromVal, validFrom, timeSlot]);

    const handleValidFromChange = (value) => {
        setValidFrom(value.format('YYYY-MM-DD'));
        setTimeSlot('');
    };

    const handleTimeSlotChange = (value) => {
        if (!validFrom) {
            setValidFrom(validFromVal.format('YYYY-MM-DD'));
        }

        setTimeSlot(value);
    };

    const handleBackClick = () => {
        resetTimeSlots();
        onBackClick();
    };

    const handleNextClick = () => {
        if (activeProduct && validFrom && (timeSlot || !activeProduct.timeSlotGroup)) {
            updateValidity({
                id: item.meta.cartId,
                validFrom: validFromVal.format('YYYY-MM-DD'),
                inclusiveValidity: getInclusiveValidity(validFromVal),
                timeSlot: timeSlot,
                timeSlotGroup: activeProduct.timeSlotGroup || '',
            });
        }

        resetTimeSlots();
        onNextClick();
    };


    const getAllowedRanges = (current) => {
        return environment.getGlobalDisabledDates(current) ||
            !isProductAndSelectionValidAtDate(
                activeProduct || undefined,
                [item.product.variantId],
                current
            );
    };

    return (
        <>
            <div className="step">
                <h3 className="step__header">
                    {i18n.t('changeTransactionDateModal.step.title', { activeStep, total })}
                </h3>
                <p className="step__description">
                    {i18n.t('changeTransactionDateModal.step.description')}
                </p>
                <div className="step__info">
                    <div className="step__info-item">
                        <Input
                            value={LocalizedString.format(item.product.name)}
                            label={i18n.t('changeTransactionDateModal.step.product.label')}
                            readOnly
                        />
                    </div>
                    <div className="step__info-item">
                        <Input
                            value={LocalizedString.format(item.product.variant)}
                            label={i18n.t('changeTransactionDateModal.step.variant.label')}
                            readOnly
                        />
                    </div>
                </div>
                {activeProduct && (
                    <>
                        {!activeProduct.timeSlotGroup && (
                            <p className="step__picker-description">
                                {i18n.t('changeTransactionDateModal.step.date.description')}
                            </p>
                        )}
                        <div className="step__picker">
                            {!activeProduct.timeSlotGroup && (
                                <div className="step__picker-box" ref={setDivRef}>
                                    {divRef && (
                                        <DatePicker
                                            open
                                            format="DD.MM.YYYY"
                                            mode="date"
                                            showToday={false}
                                            style={{ width: '270px' }}
                                            onChange={handleValidFromChange}
                                            value={validFromVal}
                                            disabledDate={getAllowedRanges}
                                            placeholder={i18n.t('changeTransactionDateModal.step.date.placeholder')}
                                            getCalendarContainer={() => divRef}
                                        />
                                    )}
                                </div>
                            )}
                            {activeProduct.timeSlotGroup && (
                                <TimeCarousel
                                    product={activeProduct}
                                    selectedVariants={[{ id: item.product.variantId, count: 1 }]}
                                    variantCount={1}
                                    arrival={validFromVal.format()}
                                    setDate={handleValidFromChange}
                                    time={timeSlotVal}
                                    setTime={handleTimeSlotChange}
                                    timeSlotGroup={oc(activeProduct).timeSlotGroup('')}
                                    hidePrices
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className="change-transaction-date-modal__footer">
                <Button onClick={handleBackClick} type="primary">
                    {i18n.t('changeTransactionDateModal.footer.back')}
                </Button>
                <Button onClick={handleNextClick} type="success" disabled={!isNextAllowed}>
                    {i18n.t('changeTransactionDateModal.footer.next')}
                </Button>
            </div>
        </>
    );
};

interface StateToProps {
    item: Entry | null;
    activeStep: number;
    activeProduct: Product | null;
    updateData: UpdateInfo | null;
}

interface DispatchToProps {
    updateValidity: (data: UpdateValidityAction) => void;
    resetTimeSlots: () => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    item: selectActiveItem(state),
    activeStep: selectActiveStep(state),
    activeProduct: selectActiveProduct(state),
    updateData: selectActiveUpdateInfo(state),
});

const mapDispatchToProps: DispatchToProps = {
    updateValidity: updateItemValidityAction,
    resetTimeSlots: resetTimeSlotOptionsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);
