import { Button, Icon, Tag } from 'antd';
import React, { useMemo } from 'react';
import { oc } from 'ts-optchain';

import { Price } from '../../../../components';
import { i18n } from '../../../../services/i18n';
import ProductValidity from '../../../ProductValidity';
import LocalizedString from '../../../LocalizedString';
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import { Entry } from '../../../../models/NewTransaction';
import { getEntryMethod } from '../../../../utils/getEntryMethod';

interface Props {
    entry: Entry;
    transactionId: string;
    onChangeDate: (item: Entry) => void;
}

export default function OrderEntry(props: Props) {
    const canBeChanged = useMemo(() => {
        const isInFuture = () => {
            if (!oc(props.entry).meta.timeSlot.from() || props.entry.meta.timeSlotType === 'day') {
                return moment(props.entry.meta.validFrom)
                    .isSameOrAfter(moment(), 'day');
            }

            return moment(
                    `${props.entry.meta.validFrom} ${props.entry.meta.timeSlot.from}`,
                    'YYYY-MM-DD HH:mm'
                )
                .isAfter(moment(), 'minute');
        };

        return props.entry.type === 'product' &&
            !!oc(props.entry).product.meta.allowDateChange() &&
            Number(oc(props.entry).ticketSystem.output.NERRORNO(-1)) === 0 &&
            isInFuture();
    }, [props.entry]);

    function renderItemValidity() {
        return oc(props).entry.product.meta.seasonal(false)
            ? i18n.t('cartItem.seasonal')
            : (
                <p>
                    <span dangerouslySetInnerHTML={{ __html: i18n.t('checkout.validity')}} />{' '}
                    <b>
                        <ProductValidity
                            from={oc(props).entry.meta.validFrom() || oc(props).entry.meta.originalValidFrom()}
                            unit={oc(props).entry.product.meta.tokenValidityUnit('days')}
                            validityAmount={oc(props).entry.product.meta.tokenValidityAmount(1)}
                            time={oc(props).entry.meta.timeSlot.from('')}
                        />
                    </b>
                </p>
            );
    }

    function getProductName() {
        if (props.entry.type === 'card-product') {
            return <LocalizedString value={props.entry.product.name} />;
        } else if (props.entry.product.name && props.entry.product.variant) {
            return (
                <>
                    <LocalizedString value={props.entry.product.name} />{' '}
                    <LocalizedString value={props.entry.product.variant} />
                </>
            );
        } else {
            return props.entry.product.name[i18n.language];
        }
    }

    function handleDownloadTicket() {
        window.open(
            `${environment.protocol}://${environment.host}/transactions/${props.transactionId}/ticket?cartId=${props.entry.meta.cartId}&lang=${i18n.language}`
        );
    }

    const handleChangeTicketDate = () => {
        props.onChangeDate(props.entry);
    };

    return (
        <>
            <div className="order-entry">
                <div className="order-entry-item">
                    <div className="order-entry-icon">
                        <img src="/mock/skipass/icon.png" alt="" />
                    </div>
                    <div className="order-entry-desc">
                        <p>
                            <strong>
                                {getProductName()}
                            </strong>
                        </p>
                        {props.entry.type !== 'card-product' && renderItemValidity()}
                        <p>{getEntryMethod(props.entry)}</p>
                    </div>
                    <div className="order-entry-right">
                        <div className="order-entry-price">
                            <Price value={oc(props).entry.amount(0)} />
                        </div>
                    </div>
                </div>
                {props.entry.type === 'product' && !props.entry.reversed && (
                    <div className="order__controls">
                        {canBeChanged && (
                            <Button
                                className="control-button"
                                type="default"
                                size="small"
                                onClick={handleChangeTicketDate}
                            >
                                {i18n.t('order.item.changeDate')}
                            </Button>
                        )}
                        <Button
                            className="control-button"
                            type="primary"
                            size="small"
                            onClick={handleDownloadTicket}
                        >
                            {!!props.entry.meta.giftTemplate
                                ? i18n.t('order.item.downloadGiftVoucher')
                                : i18n.t('order.item.downloadTicket')}
                            <Icon type="download" />
                        </Button>
                    </div>
                )}
                {props.entry.reversed && (
                    <div className="order__controls">
                        <Tag className="error-badge error-badge--plain text-center" color="orange">
                            {i18n.t('order.item.reversed')}
                        </Tag>
                    </div>
                )}
            </div>
        </>
    );
}
