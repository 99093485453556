import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { oc } from 'ts-optchain';

import { environment } from '../../environments/environment';
import { Transaction } from '../models/NewTransaction';

export const sendGtmEvent = (dataLayer: any) => {
    if (environment.production && environment.gtmIds.length) {
        TagManager.dataLayer({ dataLayer });
    }

    if (environment.production && environment.gtagIds.length) {
        if (dataLayer.event === 'page_load') {
            ReactGA.pageview(dataLayer.page);
        } else {
            ReactGA.event({
                category: dataLayer.event,
                action: dataLayer.action,
            });
        }
    }
};

export const sendGA4PurchaseEvent = (transaction: Transaction) => {
    if (environment.production && environment.ga4Ids.length) {
        ReactGA4.event('purchase', {
            transaction_id: transaction._id,
            value: transaction.amount,
            tax: transaction.entries.reduce((sum, e) => sum + Math.round((e.amount / (e.tax + 1)) * e.tax * 100), 0) / 100,
            shipping: 0,
            currency: transaction.currency,
            items: transaction.entries.map(e => {
                return {
                    item_id: e.meta.cartId,
                    item_name: oc(e).product.name.cz('') + ' ' + oc(e).product.variant.cz(''),
                    discount: oc(e).product.price.price(e.amount) - e.amount,
                    item_variant: oc(e).product.variant.cz('-'),
                    price: e.amount,
                    quantity: 1
                };
            }),
        });
    }
};

export const registerGtm = () => {
    if (environment.production && environment.gtmIds.length) {
        environment.gtmIds.forEach(id => {
            TagManager.initialize({
                gtmId: id,
            });
        });
    }

    if (environment.production && environment.gtagIds.length) {
        environment.gtagIds.forEach(id => {
            ReactGA.initialize(id);
        });
    }

    if (environment.production && environment.ga4Ids.length) {
        ReactGA4.initialize(
            environment.ga4Ids.map(id => ({ trackingId: id })),
        );
    }
};
