import { Icon } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../common/models/AppState';
import { TicketItem, Transaction } from '../../../../common/models/NewTransaction';
import { i18n } from '../../../../common/services/i18n';
import { environment } from '../../../../environments/environment';
import { Button, FeedbackBox, Layout, PageDescription, Ticket, } from '../.././../../common/components';
import { Subheader } from '../../components';
import { resetCartAction } from '../../ducks/cart';

import {
    selectConfirmationError,
    selectConfirmationStatus, selectConfirmedTransaction,
    selectTicketEntries,
    selectTransactionValue,
    startConfirmationPollingAction,
} from './ducks';

import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';
import { translationExists } from '../../../../common/utils/language-helpers';

interface DispatchToProps {
    loadTransaction: (id: string) => void;
    resetCart: () => void;
}

interface StateToProps {
    transaction: Transaction | null;
    items: TicketItem[];
    error: boolean;
    confirmed: boolean;
    price: number;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

export function ConfirmatioComponent(props: Props) {
    const [initialized, setInitialized] = useState(false);
    const [badUrlError, setBadUrlError] = useState(false);
    const [statisticsSent, setStatisticsSent] = useState(false);

    const transactionId = queryString.parse(props.history.location.search).transaction as string;

    useEffect(() => {
        if (!initialized) {
            if (!transactionId) {
                setBadUrlError(true);
            } else {
                props.loadTransaction(transactionId as string);
            }
            setInitialized(true);
        }
    });

    useEffect(
        () => {
            if (!statisticsSent && props.items.length > 0 && props.price !== undefined) {
                setStatisticsSent(true);

                sendGtmEvent({
                    event: 'page_load',
                    page: 'confirmation',
                    products: props.items.map(item => item.name),
                    price: props.price.toString(),
                });
            }
        },
        [props.items, props.price]
    );

    function handleBackHomeClick() {
        props.resetCart();
        props.history.push('/');
    }

    function renderTickets() {
        return (
            <div>
                {props.items
                    .filter(item => item.type !== 'no-card-item')
                    .map((item, index) => (
                        <Ticket key={index} transactionId={transactionId} item={item} origin={props.transaction ? props.transaction.origin : ''} />
                    ))}
            </div>
        );
    }

    function renderError() {
        return (
            <FeedbackBox
                type="negative"
                title={i18n.t('confirmation.error.title')}
                description={i18n.t('confirmation.error.description')}
            />
        );
    }

    function renderSuccess() {
        return (
            <div className="confirmation">
                <FeedbackBox
                    type="positive"
                    title={<Icon type="check-circle" />}
                    description={i18n.t('confirmation.successMessage.description')}
                />
                {props.items.some(item => item.type === 'exchange-pass') && (
                    <FeedbackBox
                        type="negative"
                        title={i18n.t('confirmation.passExchangeWarning.title')}
                        description={i18n.t('confirmation.passExchangeWarning.description')}
                    />
                )}
                {translationExists('confirmation.generalWarningBanner.title') && (
                    <FeedbackBox
                        type="negative"
                        title={i18n.t('confirmation.generalWarningBanner.title')}
                        descriptionHtml={i18n.t('confirmation.generalWarningBanner.description')}
                    />
                )}
                {renderTickets()}
                {props.items.some(i => i.isGift) && (
                    <FeedbackBox
                        type="info"
                        title={i18n.t('confirmation.giftsMessage.title')}
                        description={i18n.t('confirmation.giftsMessage.description')}
                    />
                )}
                <PageDescription
                    title={i18n.t('confirmation.bottomBanner.title')}
                    description={
                        <>
                            {i18n.t('confirmation.bottomBanner.description')}<br/><br/>
                            {i18n.t('confirmation.bottomBanner.transactionId')}: {transactionId}
                        </>
                    }
                />

                {props.items.some(i => !i.isGift) && (
                    <a
                        href={`${environment.protocol}://${
                            environment.host
                        }/transactions/${transactionId}/ticket?lang=${i18n.language}`}
                        target="_blank"
                    >
                        <Button block>{i18n.t('confirmation.download')}</Button>
                    </a>
                )}
                <Link to="/">
                    <Button block ghost onClick={handleBackHomeClick}>
                        {i18n.t('confirmation.backToHome')}
                    </Button>
                </Link>
            </div>
        );
    }

    function renderPaymentFailure() {
        return (
            <FeedbackBox
                type="negative"
                title={i18n.t('confirmation.paymentError.title')}
                description={i18n.t('confirmation.paymentError.description')}
            />
        );
    }

    return (
        <Layout.Content>
            <Subheader />
            <Layout.Container>
                {badUrlError && renderError()}
                {props.error && renderPaymentFailure()}
                {!props.error && props.confirmed && renderSuccess()}
            </Layout.Container>
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    resetCart: resetCartAction,
    loadTransaction: startConfirmationPollingAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    transaction: selectConfirmedTransaction(state),
    items: selectTicketEntries(state),
    error: selectConfirmationError(state),
    confirmed: selectConfirmationStatus(state),
    price: selectTransactionValue(state),
});

export const Confirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmatioComponent);
